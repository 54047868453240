<template>
  <a-modal
    :key="selectKey"
    class="products-edit-modal"
    title="Редактирование продуктов"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <a-form :model="product">
      <div v-if="fetching" class="spin-loader">
        <a-spin/>
      </div>
      <div v-else class="col-md-12">
        <div class="row">
          <div class="col-md-12">
<!--            <a-upload-->
<!--              name="big_image_url"-->
<!--              list-type="picture-card"-->
<!--              class="photo-uploader"-->
<!--              :show-upload-list="false"-->
<!--              :headers="getHeaders()"-->
<!--              :action="getUrl"-->
<!--              :before-upload="uploadValidation"-->
<!--              @change="uploadBigImage"-->
<!--            >-->
<!--              <img v-if="bigImageUrl" :src="bigImageUrl" alt="avatar"/>-->
<!--              <div v-else>-->
<!--                <loading-outlined v-if="loading"></loading-outlined>-->
<!--                <PlusOutlined v-else></PlusOutlined>-->
<!--                <div class="ant-upload-text">Фото (большое)</div>-->
<!--              </div>-->
<!--            </a-upload>-->
<!--            <div class="mb-4 text-center" v-if="bigImageUrl">-->
<!--              <a-button @click="removeBigImage" type="link" danger>-->
<!--                <CloseCircleOutlined/>-->
<!--                Удалить фотографию-->
<!--              </a-button>-->
<!--            </div>-->
          </div>
          <div class="col-md-12">
            <a-upload
              name="image_url"
              list-type="picture-card"
              class="photo-uploader"
              :show-upload-list="false"
              :headers="getHeaders()"
              :action="getUrl"
              :before-upload="uploadValidation"
              @change="uploadImage"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar"/>
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <PlusOutlined v-else></PlusOutlined>
                <div class="ant-upload-text">Фото</div>
              </div>
            </a-upload>
            <div class="mb-4 text-center" v-if="imageUrl">
              <a-button @click="removeImage" type="link" danger>
                <CloseCircleOutlined/>
                Удалить фотографию
              </a-button>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <a-form-item label="Категория">
                  <div v-if="editCategory" class="row">
                    <div class="col-md-10">
                      <a-select
                        placeholder="Выберите категорию"
                        v-model:value="product.category_id"
                        :options="categories"
                      ></a-select>
                    </div>
                    <div class="col-md-2">
                      <a-button @click="editCategory = false" type="text" shape="round" class="px-2 pull-right">
                        <template #icon>
                          <CloseCircleOutlined/>
                        </template>
                      </a-button>
                    </div>
                  </div>
                  <div v-else class="row">
                    <div class="col-md-10">
                      <a-input disabled placeholder="Без изменений" />
                    </div>
                    <div class="col-md-2">
                      <a-button @click="editCategory = true" type="text" shape="round" class="px-2 pull-right">
                        <template #icon>
                          <EditOutlined/>
                        </template>
                      </a-button>
                    </div>
                  </div>
                </a-form-item>
              </div>
              <div class="col-md-6">
                <a-form-item label="Описание">
                  <div v-if="editDescription" class="row">
                    <div class="col-md-10">
                      <a-input
                        placeholder="Введите описание"
                        v-model:value="product.description"
                      />
                    </div>
                    <div class="col-md-2">
                      <a-button @click="editDescription = false" type="text" shape="round" class="px-2 pull-right">
                        <template #icon>
                          <CloseCircleOutlined/>
                        </template>
                      </a-button>
                    </div>
                  </div>
                  <div v-else class="row">
                    <div class="col-md-10">
                      <a-input disabled placeholder="Без изменений" />
                    </div>
                    <div class="col-md-2">
                      <a-button @click="editDescription = true" type="text" shape="round" class="px-2 pull-right">
                        <template #icon>
                          <EditOutlined/>
                        </template>
                      </a-button>
                    </div>
                  </div>
                </a-form-item>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <a-form-item label="Цена">
                  <div v-if="editPrice" class="row">
                    <div class="col-md-10">
                      <a-input
                        placeholder="Введите цену"
                        v-model:value="product.price"
                      />
                    </div>
                    <div class="col-md-2">
                      <a-button @click="editPrice = false" type="text" shape="round" class="px-2 pull-right">
                        <template #icon>
                          <CloseCircleOutlined/>
                        </template>
                      </a-button>
                    </div>
                  </div>
                  <div v-else class="row">
                    <div class="col-md-10">
                      <a-input disabled placeholder="Без изменений" />
                    </div>
                    <div class="col-md-2">
                      <a-button @click="editPrice = true" type="text" shape="round" class="px-2 pull-right">
                        <template #icon>
                          <EditOutlined/>
                        </template>
                      </a-button>
                    </div>
                  </div>
                </a-form-item>
              </div>
              <div class="col-md-6">
                <a-form-item label="ИКПУ">
                  <div v-if="editSpic" class="row">
                    <div class="col-md-10">
                      <a-select
                        v-model:value="spic"
                        mode="multiple"
                        label-in-value
                        placeholder="Поиск икпу"
                        style="width: 100%"
                        :filter-option="false"
                        :not-found-content="spicFetching ? undefined : null"
                        :options="spicData"
                        @search="fetchSpic($event, productIds)"
                      >
                        <template v-if="spicFetching" #notFoundContent>
                          <a-spin size="small"/>
                        </template>
                      </a-select>
                    </div>
                    <div class="col-md-2">
                      <a-button @click="editSpic = false" type="text" shape="round" class="px-2 pull-right">
                        <template #icon>
                          <CloseCircleOutlined/>
                        </template>
                      </a-button>
                    </div>
                  </div>
                  <div v-else class="row">
                    <div class="col-md-10">
                      <a-input disabled placeholder="Без изменений" />
                    </div>
                    <div class="col-md-2">
                      <a-button @click="editSpic = true" type="text" shape="round" class="px-2 pull-right">
                        <template #icon>
                          <EditOutlined/>
                        </template>
                      </a-button>
                    </div>
                  </div>
                </a-form-item>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <a-form-item label="Код упаковки" v-if="codeFetching">
                  <a-spin size="small" />
                </a-form-item>
                <a-form-item label="Код упаковки" v-else-if="codeData.length">
                  <a-select
                    v-if="codeData.length"
                    v-model:value="code"
                    label-in-value
                    placeholder="Выберите код упаковки"
                    style="width: 100%"
                    :filter-option="false"
                    :not-found-content="codeFetching ? undefined : null"
                    :options="codeData"
                    show-search
                  >
                    <template v-if="codeFetching" #notFoundContent>
                      <a-spin size="small"/>
                    </template>
                  </a-select>
                </a-form-item>
              </div>
            </div>
          </div>
          <div v-if="modalVisible" class="col-md-6">
            <a-form-item label="Выберите филиал" v-if="stores.length">
              <div>
                <a-checkbox
                  v-model:checked="state.checkAll"
                  :indeterminate="state.indeterminate"
                  @change="onCheckAllChange"
                >
                  Выбрать все
                </a-checkbox>
              </div>
              <a-divider/>
              <a-checkbox-group v-model:value="state.checkedList" :options="stores">
                <template #label="{ label }">
                  <span style="color: red">{{ label }}</span>
                </template>
              </a-checkbox-group>
            </a-form-item>
          </div>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, reactive, toRaw, watch, toRefs} from 'vue';
import {CloseCircleOutlined, EditOutlined } from "@ant-design/icons-vue";
import {getHeaders} from "@/helpers/header";
import {getBase64} from "@/helpers/base64";
import apiClient from "@/services/axios";
import Product from "@/models/Product";
import {message} from "ant-design-vue";
import {debounce} from 'lodash-es';
import store from "store";

const props = defineProps({
  productIds: {
    type: Array,
    default: null,
  },
  modal: {
    type: Boolean,
    default: false,
  },
  categories: {
    type: Array,
    default: null,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let imageUrl = ref(''),
  bigImageUrl = ref(''),
  loading = ref(true),
  confirmLoading = ref(false),
  fetching = ref(true),
  modalVisible = ref(false),
  productName = ref("productName"),
  initialProduct = new Product({}),
  product = reactive(initialProduct),
  lastFetchId = 0,
  selectKey = ref(0),
  spic = ref(null),
  spicData = ref([]),
  spicValue = ref([]),
  spicFetching = ref(false),
  code = ref(null),
  codeData = ref([]),
  codeValue = ref([]),
  codeFetching = ref(false),
  editImage = ref(false),
  editCategory = ref(false),
  editDescription = ref(false),
  editPrice = ref(false),
  editSpic = ref(false);

const stores = ref([]);
const state = reactive({
  indeterminate: true,
  checkAll: false,
  checkedList: [],
});

toRefs(state);

const
  fetchSpic = debounce((value, product) => {
    if (value.length < 17)
      return false;

    lastFetchId += 1;
    const fetchId = lastFetchId;
    spicData.value = [];
    spicFetching.value = true;
    const accessToken = store.get('accessToken')
    const apiUrl = process.env.VUE_APP_BACKEND_URL;
    fetch(`${apiUrl}/cabinet/menu/fetch-spic?product=${product}&value=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": `Bearer ${accessToken}`,
      },
    })
      .then(response => response.json())
      .then(({data}) => {
        if (fetchId !== lastFetchId) {
          // for fetch callback order
          return;
        }
        // selectKey.value++;
        spicData.value = data
        spicFetching.value = false;
      });
  }, 300),
  fetchCode = () => {
    codeData.value = [];
    codeFetching.value = true;
    const accessToken = store.get('accessToken')
    const apiUrl = process.env.VUE_APP_BACKEND_URL;
    fetch(`${apiUrl}/cabinet/menu/fetch-code?product=${props.productIds}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": `Bearer ${accessToken}`,
      },
    })
      .then(response => response.json())
      .then(({data}) => {
        codeData.value = data
        codeFetching.value = false;
      });
  },
  resetForm = () => {
    Object.assign(product, new Product({}));
    modalVisible.value = false;
    emit('hide-modal')
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(product);
    delete model['name'];
    if (!product.image_url.length)
      delete model['image_url']
    if (!editCategory.value)
      delete model['category_id']
    if (!editDescription.value)
      delete model['description']
    if (!editPrice.value)
      delete model['price']
    if (!editSpic.value) {
      delete model['spic']
      delete model['spicModel']
    }

    return apiClient
      .put(`/menu/products-update`, {
        products: props.productIds,
        stores: state.checkedList,
        ...model,
      })
      .then(response => {
        if (response) {
          resetForm()
        }
      }).catch(() => {
        // console.log(err)
      }).finally(() => {
        confirmLoading.value = false;
        modalVisible.value = false;
        emit('reload')
        emit('hide-modal')
      })
  },
  uploadImage = info => {
    imageUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      product.image_url = info.file.response.data
      getBase64(info.file.originFileObj, base64Url => {
        imageUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  uploadBigImage = info => {
    bigImageUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      product.big_image_url = info.file.response.data
      getBase64(info.file.originFileObj, base64Url => {
        bigImageUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  removeImage = () => {
    imageUrl.value = ''
    product.image_url = ''

    confirmLoading.value = true;
    apiClient
      .put(`/menu/product-update?product=${props.productIds}`, {image_url: ''})
      .then(response => {
        if (response) {
          confirmLoading.value = false;
        }
      }).catch(err => {
      confirmLoading.value = false;
    }).finally(() => {
      confirmLoading.value = false;
    })
  },
  removeBigImage = () => {
    bigImageUrl.value = ''
    product.big_image_url = ''

    confirmLoading.value = true;
    apiClient
      .put(`/menu/product-update?product=${props.productIds}`, {big_image_url: ''})
      .then(response => {
        if (response) {
          confirmLoading.value = false;
        }
      }).catch(err => {
      confirmLoading.value = false;
    }).finally(() => {
      confirmLoading.value = false;
    })
  },
  uploadValidation = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG, PNG or SVG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt2M;
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/menu/upload-image'
  },
  onCheckAllChange = (e) => {
    Object.assign(state, {
      checkedList: e.target.checked ? stores.value.map(key => key.value) : [],
      indeterminate: false,
    });
  },
  fetchInitialData = (product) => {
    const code = product.spicModel?.spic
    const name = product.spicModel?.name
    const id = product.spicModel?.id
    spicData.value = [{
      label: name,
      value: id,
    }];
    spic.value = {
      value: id,
      label: code + '-' + name,
      key: id,
    };
    fetchCode()
  }
  /*
  getProduct = () => {
    apiClient.get(`/menu/product-view?uuid=${props.productIds}`)
      .then(response => {
        if (response.data) {
          stores.value = response.data.data.stores;
          modalVisible.value = true;
          nextTick(() => productName.value?.focus());
          let model = new Product(response.data.data)
          Object.assign(product, model);
          bigImageUrl.value = product.big_image_url;
          imageUrl.value = product.image_url;
          if (Object.keys(model.spicModel).length) {
            fetchInitialData(model)
          } else {
            spicData.value = [];
            spic.value = [];
          }
          state.checkedList = response.data.data['selected_stores'];
        }
      }).catch(err => {
      console.log(err)
      loading.value = false;
    })
      .finally(() => {
        loading.value = false;
        fetching.value = false;
      })
  }
*/

watch(spicValue.value, () => {
  spicData.value = [];
  spicFetching.value = false;
});

watch(codeValue.value, () => {
  codeData.value = [];
  codeFetching.value = false;
});

watch(spic, value => {
  let spic;
  if (value.length) {
    spic = value.find(() => true)?.value
  } else {
    // product.spic = value
  }
  product.spic = spic
  fetchCode()
  // apiClient.put(`/menu/product-update?product=${props.productIds}`, {spic})
  //   .then(() => {
  //   }).catch(err => console.log(err)).finally(() => {
  //   fetchCode()
  // });
})
watch(code, value => {
  product.code = value['value']
})

watch(
  () => state.checkedList,
  val => {
    state.indeterminate = !!val.length && val.length < stores.value.length;
    state.checkAll = val.length === stores.value.length;
  },
);

watch(
  () => props.modal,
  async val => {
    if (val) {
      modalVisible.value = true;
      loading.value = false;
      fetching.value = false;
    }
  },
)

</script>

<style scoped>
@import "./products.css";
</style>
