<template>
  <a-modal
    :key="selectKey"
    class="products-edit-modal"
    title="Редактировать продукт"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <a-form :model="product">
      <div v-if="fetching" class="spin-loader">
        <a-spin/>
      </div>
      <div v-else class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <a-upload
              name="image_url"
              list-type="picture-card"
              class="photo-uploader"
              :show-upload-list="false"
              :headers="getHeaders()"
              :action="getUrl"
              :custom-request="customRequest"
              :before-upload="uploadValidation"
              @change="uploadImage"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar"/>
              <div v-else>
                <LoadingOutlined v-if="loading" />
                <PlusOutlined v-else />
                <div class="ant-upload-text">Фото</div>
              </div>
            </a-upload>
            <div class="mb-4 text-center" v-if="imageUrl">
              <a-button @click="removeImage" type="link" danger>
                <CloseCircleOutlined/>
                Удалить фотографию
              </a-button>
            </div>
            <div class="vue-cropper-wrapper">
              <a-modal
                class="vue-cropper-modal"
                title="Обрезать картинку"
                :visible="isShowModal"
                :closable="true"
                @ok="getResult"
                @cancel="isShowModal = false"
              >
                <VuePictureCropper
                  v-if="imageUrl.length"
                  :box-style="{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#000000',
                    margin: 'auto',
                  }"
                  :img="imageUrl"
                  :options="{
                    viewMode: 1,
                    dragMode: 'cover',
                    aspectRatio: 4 / 3,
                  }"
                  :preset-mode="{
                    mode: 'fixedSize',
                    width: 300,
                  }"
                />
              </a-modal>
            </div>
          </div>
          <div class="col-md-12">
            <a-form-item label="Название">
              <a-input
                ref="productName"
                placeholder="Введите название"
                v-model:value="product.name"
              />
            </a-form-item>
          </div>
          <div class="col-md-12">
            <a-form-item label="Описание">
              <a-textarea
                placeholder="Введите описание"
                v-model:value="product.description"
              ></a-textarea>
            </a-form-item>
          </div>
          <div class="col-md-12">
            <a-form-item label="Переводы" v-if="product.locales.length">
              <div class="container pb-3" v-for="(model, key) in product.locales" :key="key">
                <div class="row">
                  <div class="col-md-8">
                    <div class="row pb-3">
                      <a-input placeholder="Введите название" v-model:value="model.name"></a-input>
                    </div>
                    <div class="row">
                      <a-textarea
                          placeholder="Введите описание"
                          v-model:value="model.description"
                      ></a-textarea>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="row">
                      <a-select class="pl-3" v-model:value="model.locale">
                        <a-select-option v-for="(locale, lKey) in locales" :key="lKey" :value="locale.short">{{ locale.name }}</a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <div class="row">
                      <a-button @click="removeLocale(key)" type="link" danger><MinusCircleOutlined /></a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-button
                @click="addLocale"
                shape="round"
                type="link"
                class="px-0 pt-0 pb-5"
            >
              <template #icon>
                <PlusCircleOutlined />
              </template>Добавить перевод</a-button>
          </div>
          <div class="col-md-6">
            <a-form-item label="Категория">
              <a-select
                placeholder="Выберите категорию"
                v-model:value="product.category_id"
                :options="categories"
              ></a-select>
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item label="Цена">
              <a-input
                placeholder="Введите цену"
                v-model:value="product.price"
              />
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item label="ИКПУ">
              <a-select
                v-model:value="spic"
                mode="multiple"
                label-in-value
                placeholder="Поиск икпу"
                style="width: 100%"
                :filter-option="false"
                :not-found-content="spicFetching ? undefined : null"
                :options="spicData"
                @search="fetchSpic($event, productId)"
              >
                <template v-if="spicFetching" #notFoundContent>
                  <a-spin size="small"/>
                </template>
              </a-select>
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item label="Код упаковки">
              <a-select
                v-if="codeData.length"
                v-model:value="code"
                label-in-value
                placeholder="Выберите код упаковки"
                style="width: 100%"
                :filter-option="false"
                :not-found-content="codeFetching ? undefined : null"
                :options="codeData"
                show-search
              >
                <template v-if="codeFetching" #notFoundContent>
                  <a-spin size="small"/>
                </template>
              </a-select>
            </a-form-item>
          </div>
          <div v-if="modalVisible" class="col-md-6">
            <a-form-item label="Выберите филиал" v-if="stores.length">
              <div>
                <a-checkbox
                  v-model:checked="state.checkAll"
                  :indeterminate="state.indeterminate"
                  @change="onCheckAllChange"
                >
                  Выбрать все
                </a-checkbox>
              </div>
              <a-divider/>
              <a-checkbox-group v-model:value="state.checkedList" :options="stores">
                <template #label="{ label }">
                  <span style="color: red">{{ label }}</span>
                </template>
              </a-checkbox-group>
            </a-form-item>
          </div>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, reactive, toRaw, watch, toRefs, nextTick} from 'vue';
import {
  CloseCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import {getHeaders} from "@/helpers/header";
import {getBase64} from "@/helpers/base64";
import apiClient from "@/services/axios";
import Product from "@/models/Product";
import {message} from "ant-design-vue";
import {debounce} from 'lodash-es';
import store from "store";
import VuePictureCropper, { cropper } from 'vue-picture-cropper'
import axios from "axios";

const props = defineProps({
  locales: {
    type: Array,
    default: null,
  },
  productId: {
    type: Number,
    default: 0,
  },
  modal: {
    type: Boolean,
    default: false,
  },
  categories: {
    type: Array,
    default: null,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let
  isShowModal = ref(false),
  imageUrl = ref(''),
  imageName = ref(''),
  loading = ref(true),
  confirmLoading = ref(false),
  fetching = ref(true),
  modalVisible = ref(false),
  productName = ref("productName"),
  initialProduct = new Product({}),
  product = reactive(initialProduct),
  lastFetchId = 0,
  selectKey = ref(0),
  spic = ref(null),
  spicData = ref([]),
  spicValue = ref([]),
  spicFetching = ref(false),
  code = ref(null),
  codeData = ref([]),
  codeValue = ref([]),
  codeFetching = ref(false);

const stores = ref([]);
const state = reactive({
  indeterminate: true,
  checkAll: false,
  checkedList: [],
});

toRefs(state);

const
  getResult = async () => {
    if (!cropper) return
    const blob = await cropper.getBlob()
    if (!blob) return

    const file = await cropper.getFile({
      fileName: imageName.value,
    })

    const fmData = new FormData();
    fmData.append("image_url", file);
    try {
      const res = await axios.post(
        getUrl(),
        fmData,
        { headers: getHeaders() },
      );
      product.image_url = res.data.data
    } catch (err) {
      // console.log("Error: ", err);
    }
    // console.log({ blob, file })
    // imageUrl.value = URL.createObjectURL(blob)
    isShowModal.value = false
  },
  addLocale = () => {
    product.locales.push({
      id: null,
      name: null,
      description: null,
      locale: props.locales.find(() => true).short,
    })
  },
  removeLocale = (key) => {
    product.locales.splice(key, 1)
  },
  fetchSpic = debounce((value, product) => {
    if (value.length < 17)
      return false;

    lastFetchId += 1;
    const fetchId = lastFetchId;
    spicData.value = [];
    spicFetching.value = true;
    const accessToken = store.get('accessToken')
    const apiUrl = process.env.VUE_APP_BACKEND_URL;
    fetch(`${apiUrl}/cabinet/menu/fetch-spic?product=${product}&value=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": `Bearer ${accessToken}`,
      },
    })
      .then(response => response.json())
      .then(({data}) => {
        if (fetchId !== lastFetchId) {
          // for fetch callback order
          return;
        }
        selectKey.value++;
        spicData.value = data
        spicFetching.value = false;
      });
  }, 300),
  fetchCode = () => {
    codeData.value = [];
    codeFetching.value = true;
    const accessToken = store.get('accessToken')
    const apiUrl = process.env.VUE_APP_BACKEND_URL;
    fetch(`${apiUrl}/cabinet/menu/fetch-code?product=${props.productId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": `Bearer ${accessToken}`,
      },
    })
      .then(response => response.json())
      .then(({data}) => {
        codeData.value = data
        codeFetching.value = false;
      });
  },
  resetForm = () => {
    Object.assign(product, new Product({}));
    modalVisible.value = false;
    emit('hide-modal')
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(product);

    return apiClient.put(`/menu/product-update?product=${props.productId}`, {
      stores: state.checkedList,
      ...model,
    }).then(response => {
      if (response) {
        resetForm()
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
      modalVisible.value = false;
      emit('reload')
      emit('hide-modal')
    })
  },
  uploadImage = info => {
    imageUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      product.big_image_url = info.file.response.data
      getBase64(info.file.originFileObj, base64Url => {
        imageUrl.value = base64Url;
        loading.value = false;
        isShowModal.value = true
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  removeImage = () => {
    imageUrl.value = ''
    product.image_url = ''
    product.big_image_url = ''

    confirmLoading.value = true;
    apiClient
      .put(`/menu/product-update?product=${props.productId}`, {image_url: '', big_image_url: ''})
      .then(response => {
        if (response) {
          confirmLoading.value = false;
        }
      }).catch(err => {
      confirmLoading.value = false;
    }).finally(() => {
      confirmLoading.value = false;
    })
  },
  customRequest = async options => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: getHeaders(),
      onUploadProgress: event => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        if (percent === 100) {
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("big_image_url", file);
    try {
      const res = await axios.post(
        getUrl(),
        fmData,
        config,
      );

      onSuccess("Ok");
      product.big_image_url = res.data.data
      // const data = res.data.data
    } catch (err) {
      // console.log("Error: ", err);
      onError({ err });
    }
  },
  uploadValidation = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG, PNG or SVG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt2M;
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/menu/upload-image'
  },
  onCheckAllChange = (e) => {
    Object.assign(state, {
      checkedList: e.target.checked ? stores.value.map(key => key.value) : [],
      indeterminate: false,
    });
  },
  fetchInitialData = (product) => {
    const code = product.spicModel?.spic
    const name = product.spicModel?.name
    const id = product.spicModel?.id
    spicData.value = [{
      label: name,
      value: id,
    }];
    spic.value = {
      value: id,
      label: code + '-' + name,
      key: id,
    };
    fetchCode()
  },
  getProduct = () => {
    apiClient.get(`/menu/product-view?uuid=${props.productId}`).then(response => {
      if (response.data) {
        stores.value = response.data.data.stores;
        modalVisible.value = true;
        nextTick(() => {
          if (productName.value !== 'productName')
            productName.value?.focus()
        });
        let model = new Product(response.data.data)
        Object.assign(product, model);
        imageUrl.value = product.big_image_url;
        if (Object.keys(model.spicModel).length) {
          fetchInitialData(model)
        } else {
          spicData.value = [];
          spic.value = [];
        }
        state.checkedList = response.data.data['selected_stores'];
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false;
      fetching.value = false;
    })
  }

watch(spicValue.value, () => {
  spicData.value = [];
  spicFetching.value = false;
});

watch(codeValue.value, () => {
  codeData.value = [];
  codeFetching.value = false;
});

watch(spic, value => {
  let spic;
  if (value.length) {
    spic = value.find(() => true)?.value
  } else {
    // product.spic = value
  }
  product.spic = spic
  apiClient.put(`/menu/product-update?product=${props.productId}`, {spic}).then(() => {
  }).catch(() => {
    // console.log(err)
  }).finally(() => {
    fetchCode()
  });
})
watch(code, value => {
  product.code = value['value']
})

watch(
  () => state.checkedList,
  val => {
    state.indeterminate = !!val.length && val.length < stores.value.length;
    state.checkAll = val.length === stores.value.length;
  },
);

watch(
  () => props.modal,
  async val => {
    if (val) {
      await getProduct();
    }
  },
)

</script>

<style scoped>
@import "./products.css";
</style>
