<template>
  <div>
    <a-form layout="horizontal">
      <div class="w-100">
        <div class="row">
          <div class="col-md-3">
            <a-form-item>
              <template #label>
                <a-typography-title :level="5">Выберите бренд</a-typography-title>
              </template>
              <a-select
                :options="brands"
                v-model:value="selectedBrand"
                @change="fetchCategories"
                :option-filter-prop="'label'"
                style="width: 250px"
                placeholder="Бренд"
                size="large"
                show-search
                allow-clear
              />
            </a-form-item>
          </div>
          <div class="col-md-3">
            <a-form-item>
              <template #label>
                <a-typography-title :level="5">Выберите категорию</a-typography-title>
              </template>
              <a-select
                @change="updateRoute"
                :options="categoriesFilter"
                v-model:value="selectedCategory"
                placeholder="Категория"
                style="width: 250px"
                size="large"
                show-search
                allow-clear
              />
            </a-form-item>
          </div>
          <div class="col-md-6 pt-3">
            <a-button v-if="!loading" @click="editSpicModalVisible = true" class="float-right mt-4" type="primary">
              Обновить ИКПУ
            </a-button>
          </div>
        </div>
      </div>
    </a-form>
    <a-affix :offset-top="0">
      <a-page-header class="header-affix mb-4">
        <a-form>
          <div class="row">
            <div class="col-12 mb-4">
              <div class="row">
                <div class="col-md-2">
                  <a-input v-model:value="productSearchSpic" placeholder="ИКПУ" @change="searchProductSpic" allow-clear />
                </div>
                <div class="col-md-2">
                  <a-input v-model:value="productSearchCode" placeholder="Код упаковки" @change="searchProductCode" allow-clear />
                </div>
                <div class="col-md-2">
                  <a-input v-model:value="productSearchName" placeholder="Название" @change="searchProductName" allow-clear />
                </div>
                <div class="col-md-2">
                  <a-input v-model:value="productSearchDesc" placeholder="Описание" @change="searchProductDesc" allow-clear />
                </div>
                <div class="col-md-2">
                  <a-select show-search v-model:value="queryParams.invalid" placeholder="ИКПУ" style="width: 100%">
                    <a-select-option value="">Все</a-select-option>
                    <a-select-option value="invalid">Невалидные ИКПУ</a-select-option>
                    <a-select-option value="empty">Пустые ИКПУ</a-select-option>
                  </a-select>
                </div>
  <!--                <div class="col-md-8">-->
  <!--                  <a-input v-model:value="productSearchAll" placeholder="Поиск" @change="searchProductAll"-->
  <!--                           allow-clear/>-->
  <!--                </div>-->
                <div class="col-md-2 text-right">
                  <a-button @click="clearFilters" class="w-100 text-right" shape="round" type="link">
                    <template #icon>
                      <CloseCircleOutlined/>
                    </template>
                    Сбросить фильтр
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form>
      </a-page-header>
    </a-affix>
    <vb-edit-product
      :key="editModalKey"
      :modal="modalVisible"
      :product-id="productId"
      :categories="categories"
      :locales="locales"
      @reload="reload()"
      @hide-modal="hideModal()"
    />
    <vb-edit-products
      :key="multipleEditModalKey"
      :modal="multipleModalVisible"
      :product-ids="productIds"
      :categories="categories"
      @reload="reload();clearSelection()"
      @hide-modal="hideMultipleModal()"
    />
    <div class="clearfix">
      <a-dropdown
        class="my-2"
        v-if="selectedRowKeys.length"
      >
        <template #overlay>
          <a-menu>
            <a-menu-item key="1" @click="multipleUpdateModal">
              Редактировать
            </a-menu-item>
            <a-menu-item key="2" @click="openSpicModal">
              Обновить ИКПУ
            </a-menu-item>
            <a-menu-item key="3" @click="deleteProductsModal = true">
              Удалить выбранные продукты
            </a-menu-item>
            <a-menu-item key="4" @click="updateCategoriesModalVisible = true">
              Перенести в другую категорию
            </a-menu-item>
          </a-menu>
        </template>
        <a-button type="primary">
          Выберите действие
          <DownOutlined/>
        </a-button>
      </a-dropdown>
      <a-typography-text v-if="selectedRowKeys.length" secondary class="mx-3">
        (Выделено: {{ selectedRowKeys.length }} элементов)
      </a-typography-text>
      <a-button
        v-if="showSelectAllButton"
        @click="selectAll"
        type="link"
      >Выделить все ({{ total }})
      </a-button>
      <a-button
        v-if="selectedRowKeys.length"
        @click="clearSelection"
        type="text"
        class="text-red"
      >
        Отменить
      </a-button>
    </div>
    <a-table
      :row-selection="rowSelection"
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      :expand-row-by-click="true"
      :custom-row="(record) => {
        return {
          ondblclick: () => {
            updateModal(record['uuid']);
          }
        }
      }"
      @change="tableChange"
      row-key="id"
      id="data-table-products"
    >
      <template #title>
        <div class="pb-1 overflow-hidden" v-if="brandId">
          <h6 class="pull-left mb-0">
            <router-link :to="{ name: 'categories', params: { brand: brandId } }">Категории</router-link>
          </h6>
          <h6 class="pull-left mb-0">
            <RightOutlined :style="{ fontSize: '10px', margin: '4px 7px 0', display: 'block' }"/>
          </h6>
          <h6 class="font-weight-light pull-left mb-0 text-grey">{{ categoryName ?? 'Без категории' }}</h6>
        </div>
      </template>
      <template #description="{ record }">
        <div v-if="editDescId === record.id" class="row">
          <div class="col-md-10 px-0">
            <div class="row">
              <div class="col-md-10 px-0">
                <a-input
                  :id="`desc-${editDescId}`"
                  @keydown.esc="editDescId = 0"
                  @pressEnter="updateColumn(record['uuid'], record.name, 'description', record.description); editDescId = 0"
                  v-model:value="record.description"></a-input>
              </div>
              <div class="col-md-2 justify-content-end row p-0">
                <a-button
                  @click="updateColumn(record['uuid'], record.name, 'description', null); editDescId = 0"
                  type="link"
                ><CloseCircleOutlined /></a-button>
              </div>
            </div>
          </div>
          <div class="col-md-2 justify-content-end row p-0">
            <a-button
              @click="updateColumn(record['uuid'], record.name, 'description', record.description); editDescId = 0"
              type="text" shape="round" class="save-button">
              <template #icon>
                <SaveOutlined/>
              </template>
            </a-button>
          </div>
        </div>
        <div v-else>
          <div class="pull-left" @click="onEditInput('desc', record.id)" :style="{ lineHeight: '30px' }">{{ record.description }}</div>
          <a-button @click="onEditInput('desc', record.id)" type="text" shape="round" class="px-2 pull-right">
            <template #icon>
              <EditOutlined/>
            </template>
          </a-button>
        </div>
      </template>
      <template #name="{ record }">
        <div v-if="editNameId === record.id" class="row">
          <div class="product-info">
            <div v-if="record['image_url']" class="product-image" :style="'background-image: url(' + record['image_url'] + ')'"></div>
            <a-input :id="`name-${editNameId}`" @pressEnter="updateColumn(record['uuid'], record.name); editNameId = 0"
                     v-model:value="record.name" @keydown.esc="editNameId = 0"></a-input>
            <a-button @click="updateColumn(record['uuid'], record.name); editNameId = 0" type="text" shape="round"
                      class="save-button">
              <template #icon>
                <SaveOutlined/>
              </template>
            </a-button>
          </div>
        </div>
        <div v-else class="row">
          <div class="product-info">
            <div class="product-title">
              <div v-if="record['image_url']" class="product-image" :style="'background-image: url(' + record['image_url'] + ')'"></div>
              <span @click="onEditInput('name', record.id)" class="product-name cursor-pointer">{{ record.name }}</span>
            </div>
            <a-button @click="onEditInput('name', record.id)" type="text" shape="round" class="px-2 pull-right">
              <template #icon>
                <EditOutlined/>
              </template>
            </a-button>
          </div>
        </div>
      </template>
      <template #category="{ record }">
        <div v-if="editCategoryId === record.id" class="row">
          <div class="col-md-9 px-0">
            <a-select
              :id="`category-${editCategoryId}`"
              v-model:value="record.category_id"
              placeholder="Выберите категорию"
              style="width: 100%"
              :filter-option="false"
              :options="categories"
              @keydown.esc="editCategoryId = 0"
              show-search
            >
            </a-select>
          </div>
          <div class="col-md-3 justify-content-end row p-0">
            <a-button
              @click="updateColumn(record['uuid'], record.name, 'category_id', record.category_id); editCategoryId = 0"
              type="text" shape="round" class="save-button">
              <template #icon>
                <SaveOutlined/>
              </template>
            </a-button>
          </div>
        </div>
        <div v-else class="container-fluid">
          <div class="row">
            <a-select
              class="col-md-10"
              v-model:value="record.category_id"
              placeholder="Выберите категорию"
              style="width: 100%"
              :filter-option="false"
              :options="categories"
              disabled
              show-search
            >
            </a-select>
            <a-button @click="onEditInput('category', record.id)" type="text" shape="round" class="px-2 pull-right col-md-2">
              <template #icon>
                <EditOutlined/>
              </template>
            </a-button>
          </div>
        </div>
      </template>
      <template #spic="{ record }">
        <div v-if="editSpicId === record.id" class="row">
          <div class="col-md-10 px-0">
            <a-select
              :id="`spic-${editSpicId}`"
              v-model:value="spic"
              mode="multiple"
              label-in-value
              placeholder="Поиск икпу"
              style="width: 100%"
              :filter-option="false"
              :options="spicData"
              @search="fetchSpic($event, record.id)"
              :not-found-content="spicFetching ? undefined : null"
              @keydown.esc="editSpicId = 0"
              show-search
            >
              <template v-if="spicFetching" #notFoundContent>
                <a-spin size="small"/>
              </template>
            </a-select>
          </div>
          <div class="col-md-2 px-0 justify-content-end row">
            <a-button @click="saveSpic(record)" type="text" shape="round" class="save-button">
              <template #icon>
                <SaveOutlined/>
              </template>
            </a-button>
          </div>
        </div>
        <div v-else class="product-spic">
          <div class="w-100" @click="onEditInput('spic', record.id);fetchSpic(record.spicModel?.spic, record.id)">
            <a-tooltip placement="top" v-if="record['spicModel']">
              <template #title>
                <div
                  class="text-center"
                >{{ record['spicModel']?.['name'] }}
                </div>
              </template>
              <div
                class="product-ellipsis"
                :class="{ 'text-red': !record['spic_validated'] }"
              >{{ record['spicModel']?.['name'] }}
              </div>
            </a-tooltip>
            <a-typography-text :class="{ 'text-red': !record['spic_validated'] }" v-if="record['spicModel']" strong>
              <CopyOutlined/>
              {{ record['spicModel']?.['spic'] }}
            </a-typography-text>
            <a-typography-text v-if="!record['spicModel'] && !record['spic']" type="secondary">(ИКПУ не задан)
            </a-typography-text>
            <a-tooltip placement="top" v-if="!record['spicModel'] && record['spic']">
              <template #title>
                Невалидный ИКПУ
              </template>
              <a-typography-text type="danger">{{ record.spic }}</a-typography-text>
            </a-tooltip>
          </div>
          <a-button @click="onEditInput('spic', record.id);fetchSpic(record.spicModel?.spic, record.id)" type="text" shape="round" class="px-2 pull-right">
            <template #icon>
              <EditOutlined/>
            </template>
          </a-button>
        </div>
      </template>
      <template #code="{ record }">
        <div v-if="editCodeId === record.id" class="row">
          <div class="col-md-9 px-0">
            <a-select
              :id="`code-${editCodeId}`"
              v-if="codeData.length"
              v-model:value="code"
              label-in-value
              placeholder="Выберите код упаковки"
              style="width: 100%"
              :filter-option="false"
              :not-found-content="codeFetching ? undefined : null"
              :options="codeData"
              @keydown.esc="editCodeId = 0"
              show-search
            >
              <template v-if="codeFetching" #notFoundContent>
                <a-spin size="small"/>
              </template>
            </a-select>
            <div v-else class="container">
              <a-spin v-if="codeFetching" size="small"/>
              <a-typography-text v-else type="secondary" style="line-height: 32px">(ИКПУ не задан)</a-typography-text>
            </div>
          </div>
          <div class="col-md-3 justify-content-end row p-0">
            <a-button @click="saveCode(record)" type="text" shape="round" class="save-button">
              <template #icon>
                <SaveOutlined/>
              </template>
            </a-button>
          </div>
        </div>
        <div v-else class="product-code">
          <div class="w-100" @click="onEditInput('code', record.id)">
            <a-tooltip placement="top" v-if="record['codeModel']">
              <template #title>
                <div class="text-center">{{ record['codeModel']?.['name'] }}</div>
              </template>
              <div
                  class="product-ellipsis"
                  :class="{ 'text-red': !record['code_validated'] }"
              >{{ record['codeModel']?.['name'] }}</div>
            </a-tooltip>
            <a-typography-text :class="{ 'text-red': !record['code_validated'] }" v-if="record['codeModel']" strong style="line-height: 32px">
              <CopyOutlined/>
              {{ record['codeModel']?.['code'] }}
            </a-typography-text>
            <a-typography-text v-if="!record['codeModel']" type="secondary" style="line-height: 32px">(Код упаковки не
              задан)
            </a-typography-text>
          </div>
          <a-button @click="onEditInput('code', record.id)" type="text" shape="round" class="px-2 pull-right">
            <template #icon>
              <EditOutlined/>
            </template>
          </a-button>
        </div>
      </template>
      <template #price="{ record }">
        <div v-if="editPriceId === record.id" class="row">
          <div class="col-md-10 px-0">
            <a-input :id="`price-${editPriceId}`"
                     @keydown.esc="editPriceId = 0"
                     @pressEnter="updateColumn(record['uuid'], record.name, 'price', record.price); editPriceId = 0"
                     v-model:value="record.price"></a-input>
          </div>
          <div class="col-md-2 justify-content-end row">
            <a-button @click="updateColumn(record['uuid'], record.name, 'price', record.price); editPriceId = 0"
                      type="text" shape="round" class="save-button">
              <template #icon>
                <SaveOutlined/>
              </template>
            </a-button>
          </div>
        </div>
        <div v-else>
          <div class="pull-left" @click="onEditInput('price', record.id)" :style="{ lineHeight: '30px' }">{{ record.price }}</div>
          <a-button @click="onEditInput('price', record.id)" type="text" shape="round" class="px-2 pull-right">
            <template #icon>
              <EditOutlined/>
            </template>
          </a-button>
        </div>
      </template>
      <template #weight="{ record }">
        <div v-if="editWeightId === record.id" class="row">
          <div class="col-md-10 px-0">
            <a-input :id="`weight-${editWeightId}`"
                     @keydown.esc="editWeightId = 0"
                     @pressEnter="updateColumn(record['uuid'], record.name, 'weight', record.weight); editWeightId = 0"
                     v-model:value="record.weight"></a-input>
          </div>
          <div class="col-md-2 justify-content-end row">
            <a-button @click="updateColumn(record['uuid'], record.name, 'weight', record.weight); editWeightId = 0"
                      type="text" shape="round" class="save-button">
              <template #icon>
                <SaveOutlined/>
              </template>
            </a-button>
          </div>
        </div>
        <div v-else>
          <div class="pull-left" @click="onEditInput('weight', record.id)" :style="{ lineHeight: '30px' }">{{ record.weight }}</div>
          <a-button @click="onEditInput('weight', record.id)" type="text" shape="round" class="px-2 pull-right">
            <template #icon>
              <EditOutlined/>
            </template>
          </a-button>
        </div>
      </template>
      <template #unit="{ record }">
        <div v-if="editUnitId === record.id" class="row">
          <div class="col-md-12 pl-0">
<!--            <a-input :id="`unit-${editUnitId}`"-->
<!--                     @keydown.esc="editUnitId = 0"-->
<!--                     @pressEnter="updateColumn(record['uuid'], record.name, 'unit', record.unit); editUnitId = 0"-->
<!--                     v-model:value="record.unit"></a-input>-->
            <a-select
              v-model:value="record.unit"
              style="width: 100%"
              @change="updateColumn(record['uuid'], record.name, 'unit', record.unit.toString()); editUnitId = 0"
              :placeholder="units.find(unit => unit.value === record.unit)?.label"
            >
              <a-select-option v-for="unit in units" :key="unit.value" :value="unit.value">{{ unit.label }}</a-select-option>
            </a-select>
          </div>
        </div>
        <div v-else>
          <div class="pull-left" @click="onEditInput('unit', record.id)" :style="{ lineHeight: '30px' }">{{ units.find(unit => unit.value === record.unit)?.label }}</div>
          <a-button @click="onEditInput('unit', record.id)" type="text" shape="round" class="px-2 pull-right">
            <template #icon>
              <EditOutlined/>
            </template>
          </a-button>
        </div>
      </template>
      <template #date="{ record }">
        <span>
          {{ formattedDatetime(record['added_on']) }}
        </span>
        <span v-if="record.author">
          ({{ record.author }})
        </span>
      </template>
      <template #status="{ record }">
        <a-tag
          @click="updateColumn(record['uuid'], record.name, 'status', 0)"
          v-if="record.status"
          color="#108ee9"
          class="cursor-pointer"
        >Показать</a-tag>
        <a-tag
          @click="updateColumn(record['uuid'], record.name, 'status', 1)"
          class="cursor-pointer"
          v-else
        >Скрыть</a-tag>
      </template>
      <template #disabled="{ record }">
        <a-tag
          @click="updateColumn(record['uuid'], record.name, 'disabled', 0)"
          v-if="record.disabled"
          class="cursor-pointer"
        >Недоступен</a-tag>
        <a-tag
          @click="updateColumn(record['uuid'], record.name, 'disabled', 1)"
          class="cursor-pointer"
          color="#108ee9"
          v-else
        >Доступен</a-tag>
      </template>
      <template #action="{ record }">
        <div class="w-100 text-right">
          <a-tooltip placement="top" class="mx-2">
            <template #title>Модификации</template>
            <a @click="router.push({name: 'modifiers', params: { brand: record['brand_id'], category: record['category_id'], product: record['id'] }})"><i class="fe fe-settings"></i></a>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2">
            <template #title>Редактировать</template>
            <a @click="updateModal(record['uuid'])"><i class="fe fe-edit"></i></a>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2">
            <template #title>Удалить</template>
            <a @click="showDeleteModal(record['uuid'])"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <a-modal
      class="spic-modal"
      title="Обновить ИКПУ"
      :visible="spicModalVisible"
      :closable="true"
      :confirm-loading="false"
      :ok-button-props="{disabled: !codeSelect || !codeSelectValue}"
      :ok-text="'Обновить и сохранить'"
      @ok="saveCodeModal"
      @cancel="spicModalVisible = false"
    >
      <a-select
        ref="spicSelect"
        v-model:value="spicUpdate"
        mode="multiple"
        label-in-value
        placeholder="ИКПУ"
        style="width: 100%"
        :filter-option="false"
        :not-found-content="spicFetching ? undefined : null"
        :options="spicDataUpdate"
        @search="fetchSpicUpdate"
        show-search
      >
        <template v-if="spicFetching" #notFoundContent>
          <a-spin size="small"/>
        </template>
      </a-select>
      <a-button
        :disabled="!spicUpdate"
        :style="{marginTop: '12px', marginBottom: '12px'}"
        :loading="spiceUpdateLoading"
        @click="saveSpicUpdate"
      >
        Сохранить
      </a-button>
      <a-select
        v-if="codeSelect"
        v-model:value="codeSelectValue"
        placeholder="Код упаковки"
        style="width: 100%"
        :filter-option="false"
        :not-found-content="spicFetching ? undefined : null"
        :options="codeOptionDataModal"
        show-search
      >
        <template v-if="spicFetching" #notFoundContent>
          <a-spin size="small"/>
        </template>
      </a-select>
    </a-modal>
    <a-modal
      class="update-categories-modal"
      title="Обновить категорию"
      :visible="updateCategoriesModalVisible"
      :closable="true"
      :confirm-loading="updateCategoryLoading"
      @ok="updateCategories"
      @cancel="updateCategoriesModalVisible = false"
    >
      <a-select
        v-model:value="categoryId"
        placeholder="Выберите категорию"
        style="width: 100%"
        :filter-option="false"
        :options="categories"
        show-search
      >
      </a-select>
    </a-modal>
    <a-modal
      v-model:visible="deleteModal"
      @cancel="hideDeleteModal"
      :footer="null"
    >
      <a-typography-title :level="5">Вы уверены что хотите удалить продукт?</a-typography-title>
      <div class="footer overflow-hidden pt-2">
        <a-button @click="deleteProduct" type="primary" class="float-right">Да</a-button>
        <a-button @click="hideDeleteModal" type="secondary" class="float-right mx-2">Нет</a-button>
      </div>
    </a-modal>
    <a-modal
      v-model:visible="deleteProductsModal"
      @cancel="deleteProductsModal = false"
      :footer="null"
    >
      <a-typography-title :level="5">Вы действительно хотите удалить выбранные продукты?</a-typography-title>
      <div class="footer overflow-hidden pt-2">
        <a-button @click="deleteProducts" type="primary" class="float-right">Да</a-button>
        <a-button @click="deleteProductsModal = false" type="secondary" class="float-right mx-2">Нет</a-button>
      </div>
    </a-modal>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
    <ikpu
      :modal="editSpicModalVisible"
      :data-source="dataSource"
      :total="total"
      :brand="brandId"
      @close-spic-modal="closeEditSpicModal"
    />
  </div>
</template>

<script setup>
import {
  DownOutlined,
  EditOutlined,
  RightOutlined,
  SaveOutlined,
  CopyOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons-vue';
import {computed, onMounted, reactive, ref, unref, watch, toRefs, nextTick} from 'vue';
import VbEditProduct from "@/views/store/menu/products/edit.vue";
import VbEditProducts from "@/views/store/menu/products/multiple-edit.vue";
import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
import {message} from 'ant-design-vue';
import apiClient from "@/services/axios";
import {debounce} from 'lodash-es';
import store from "store";
import Ikpu from "@/views/store/menu/products/components/ikpu.vue";

let
  timer = null,
  brands = ref([]),
  selectedBrand = ref('null'),
  selectedCategory = ref('null'),
  categoriesFilter = ref([]),
  deleteModal = ref(false),
  deleteId = ref(0),
  deleteProductsModal = ref(false),
  productSearchSpic = ref(''),
  productSearchCode = ref(''),
  productSearchName = ref(''),
  productSearchDesc = ref(''),
  productSearchAll = ref(''),
  showSelectAllButton = ref(false),
  editNameId = ref(0),
  editCategoryId = ref(0),
  editDescId = ref(0),
  editSpicId = ref(0),
  editCodeId = ref(0),
  editPriceId = ref(0),
  editWeightId = ref(0),
  editUnitId = ref(0),
  productId = ref(0),
  productIds = ref([]),
  brandId = ref(0),
  locales = ref([]),
  spicModalVisible = ref(false),
  spicSelect = ref("spicSelect"),
  editSpicModalVisible = ref(false),
  updateCategoriesModalVisible = ref(false),
  categoryName = ref(''),
  dataSource = ref([]),
  loading = ref(false),
  spiceUpdateLoading = ref(false),
  updateCategoryLoading = ref(false),
  modalVisible = ref(false),
  multipleModalVisible = ref(false),
  total = ref(0),
  categories = ref([]),
  categoryId = ref(null),
  editModalKey = ref(0),
  multipleEditModalKey = ref(0),
  lastFetchId = 0,
  units = ref([]),
  spic = ref(null),
  spicUpdate = ref(null),
  spicData = ref([]),
  spicDataUpdate = ref([]),
  spicValue = ref([]),
  spicFetching = ref(false),
  code = ref(null),
  codeProductId = ref(0),
  codeData = ref([]),
  codeOptionDataModal = ref([]),
  codeValue = ref([]),
  codeFetching = ref(false),
  codeSelectValue = ref(''),
  codeSelect = ref(false);

const router = useRouter(),
  selectedRowKeys = ref([]),
  route = useRoute(),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    spic: '',
    code: '',
    name: '',
    invalid: '',
    description: '',
  },
  searchProductSpic = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3)
      return;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({spic: e.target.value})
    }, 800);
  },
  searchProductCode = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3)
      return;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({code: e.target.value})
    }, 800);
  },
  searchProductName = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3)
      return;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({name: e.target.value})
    }, 800);
  },
  searchProductDesc = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3)
      return;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({description: e.target.value})
    }, 800);
  },
  queryParams = reactive({...initialParams}),
  clearFilters = () => {
    Object.assign(queryParams, initialParams);

    productSearchSpic.value = '';
    productSearchCode.value = '';
    productSearchName.value = '';
    productSearchDesc.value = '';
    productSearchAll.value = '';
  },
  columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      fixed: 'left',
      sorter: true,
      slots: {customRender: 'name'},
    },
    {
      title: 'Категория',
      dataIndex: 'category_id',
      key: 'category_id',
      width: 200,
      sorter: true,
      slots: {customRender: 'category'},
    },
    {
      title: 'ИКПУ',
      dataIndex: 'spic',
      key: 'spic',
      width: 300,
      sorter: true,
      slots: {customRender: 'spic'},
    },
    {
      title: 'Код упаковки',
      dataIndex: 'code',
      key: 'code',
      width: 250,
      sorter: true,
      slots: {customRender: 'code'},
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      width: 500,
      key: 'description',
      sorter: true,
      slots: {customRender: 'description'},
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
      width: 130,
      sorter: true,
      slots: {customRender: 'price'},
    },
    {
      title: 'Вес',
      dataIndex: 'weight',
      key: 'weight',
      width: 100,
      sorter: true,
      slots: {customRender: 'weight'},
    },
    {
      title: 'Ед. изм',
      dataIndex: 'unit',
      key: 'unit',
      width: 120,
      sorter: true,
      slots: {customRender: 'unit'},
    },
    {
      title: 'Добавлен',
      dataIndex: 'added_on',
      key: 'added_on',
      width: 200,
      sorter: true,
      slots: {customRender: 'date'},
    },
    {
      title: 'Отображение',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      sorter: true,
      slots: {customRender: 'status'},
    },
    {
      title: 'Доступность',
      dataIndex: 'disabled',
      key: 'disabled',
      width: 150,
      sorter: true,
      slots: {customRender: 'disabled'},
    },
    {
      key: 'action',
      fixed: 'right',
      width: 120,
      slots: {customRender: 'action'},
    },
  ],

  formattedDatetime = (date) => {
    date = date.replace(/ /g, "T")
    let d = new Date(date)
    const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
      timeStyle: 'medium',
    })
    const dateFormatter = new Intl.DateTimeFormat('ru-RU')
    return dateFormatter.format(d) + ' ' + timeFormatter.format(d)
  },
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  openSpicModal = () => {
    spicUpdate.value = null
    spicDataUpdate.value = []
    codeSelect.value = false;
    codeSelectValue.value = "";
    spicModalVisible.value = true;
    nextTick(() => spicSelect.value?.focus());
  },
  updateCategories = () => {
    updateCategoryLoading.value = true
    return apiClient.put(`menu/products-update`, {
      products: selectedRowKeys.value,
      category_id: categoryId.value,
    }).then(response => {
      if (response) {
        message.success('Продукты успешно удалены')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      reload()
      updateCategoryLoading.value = false
      updateCategoriesModalVisible.value = false
    }).finally(() => {
      clearSelection()
    });
  },
  deleteProducts = () => {
    return apiClient.delete('menu/products-delete', {
      data: {products: selectedRowKeys.value},
    }).then(response => {
      if (response) {
        message.success('Продукты успешно удалены')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      reload()
    }).finally(() => {
      deleteProductsModal.value = false
      clearSelection()
    });
  },
  onSelectChange = changeableRowKeys => {
    selectedRowKeys.value = changeableRowKeys;
  },
  rowSelection = computed(() => {
    return {
      selectedRowKeys: unref(selectedRowKeys),
      onChange: onSelectChange,
      hideDefaultSelections: true,
      selections: null,
    };
  }),
  clearSelection = () => {
    selectedRowKeys.value = []
  },
  selectAll = () => {
    const params = {...queryParams}
    return apiClient.get(`/menu/product-ids?category=${route.params.category}`, {
      params,
    }).then(({data}) => {
      if (data['data']) {
        selectedRowKeys.value = data['data']
      }
    })
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({sort: sign + sorter.field})
    }
  },
  fetchSpic = debounce((value, product) => {
    // if (value.length < 17)
    //   return false;

    lastFetchId += 1;
    const fetchId = lastFetchId;
    spicData.value = [];
    spicFetching.value = true;
    const accessToken = store.get('accessToken')
    const apiUrl = process.env.VUE_APP_BACKEND_URL;
    fetch(`${apiUrl}/cabinet/menu/fetch-spic?product=${product}&value=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": `Bearer ${accessToken}`,
      },
    }).then(response => response.json()).then(({data}) => {
      // console.log(data, 'data')
      if (fetchId !== lastFetchId) {
        // for fetch callback order
        return;
      }
      spicData.value = data;
      spicFetching.value = false;
    });
  }, 300),
  fetchSpicUpdate = debounce((value) => {
    if (value.length < 17)
      return false;

    spicDataUpdate.value = [];
    spicFetching.value = true;
    const accessToken = store.get('accessToken')
    const apiUrl = process.env.VUE_APP_BACKEND_URL;
    const product = selectedRowKeys.value[0] ?? null;
    fetch(`${apiUrl}/cabinet/menu/fetch-spic?product=${product}&value=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": `Bearer ${accessToken}`,
      },
    })
      .then(response => response.json())
      .then(({data}) => {
        spicDataUpdate.value = data;
        spicFetching.value = false;
      });
  }, 300),
  saveSpicUpdate = async () => {
    const spic = spicUpdate.value.find(() => true)?.value;
    spiceUpdateLoading.value = true;
    codeSelect.value = false;

    return apiClient.put(`menu/products-update`, {
      products: selectedRowKeys.value,
      spic: spic,
    }).then((response) => {
      if (response) {
        fetchCode(selectedRowKeys.value[0]);
        message.success('Продукты успешно обновлены');
        codeSelect.value = true;
      }
    }).catch(() => {
      codeSelect.value = false;
      // console.log(err)
    }).finally(() => {
      spiceUpdateLoading.value = false;
      reload()
    });
  },
  saveSpic = (product) => {
    let value;
    if (spic.value) {
      if (spic.value.length) {
        value = spic.value.find(() => true)?.value
      } else {
        value = spic.value?.value
      }
    }
    if (!value)
      value = 'null'
    updateColumn(product['uuid'], product['name'], 'spic', value)
    editSpicId.value = 0
  },
  saveCode = (product) => {
    updateColumn(product['uuid'], product['name'], 'code', code.value?.value)
    editCodeId.value = 0
  },
  saveCodeModal = () => {
    const code = codeSelectValue.value;
    spiceUpdateLoading.value = true;
    codeSelect.value = false;

    return apiClient.put(`menu/products-update`, {
      products: selectedRowKeys.value,
      code: code,
    }).then((response) => {
      if (response) {
        fetchCode(selectedRowKeys.value[0]);
        message.success('Продукты успешно обновлены');
        codeSelect.value = true;
      }
    }).catch(() => {
      codeSelect.value = false;
      spicModalVisible.value = true;
      // console.log(err)
    }).finally(() => {
      spiceUpdateLoading.value = false;
      spicModalVisible.value = false;
      clearSelection()
      reload()
    });
  },
  fetchCode = (productId) => {
    codeData.value = [];
    codeFetching.value = true;
    const accessToken = store.get('accessToken');
    const apiUrl = process.env.VUE_APP_BACKEND_URL;
    fetch(`${apiUrl}/cabinet/menu/fetch-code?product=${productId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": `Bearer ${accessToken}`,
      },
    })
      .then(response => response.json())
      .then(({data}) => {
        codeProductId.value = productId
        codeData.value = data
        codeOptionDataModal.value = data
        codeSelectValue.value = data[0]['id']
        codeFetching.value = false;
        nextTick(() => {
          document.getElementById(`code-${editCodeId.value}`).focus();
        })
      });
  },
  fetchCategories = (brand) => {
    if (!brand || brand === 'null') {
      apiClient.get(`/menu/categories-list?brand=${brand}`).then(({data}) => {
        brand = data.data.brands.find(brand => brand.value !== 'null')?.value
        selectedBrand.value = brand
        selectedCategory.value = 'all'
        router.replace({
          name: 'products',
          params: { brand, category: 'all' }
        })
      })
    }
    if (!brand) brand = 'null'
    loading.value = true
    return apiClient.get(`/menu/categories-list?brand=${brand}`).then(({data}) => {
      categoriesFilter.value = data.data.categories
      brands.value = data.data.brands
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  updateRoute = (category) => {
    if (!category) category = 'all'
    router.push({
      name: 'products',
      params: { brand: selectedBrand.value, category: category }
    })
  },
  updateColumn = (id, name, column = null, value = null) => {
    let data = {};
    data['name'] = name;
    if (column) {
      data[column] = value;
    }
    apiClient.put(`/menu/product-update?product=${id}`, data).then(response => {
      if (response) {
        message.success('Продукт успешно обновлен')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      reload()
    });
  },
  updateModal = (id) => {
    productId.value = id;
    modalVisible.value = true;
  },
  multipleUpdateModal = () => {
    productIds.value = selectedRowKeys.value;
    multipleModalVisible.value = true
  },
  showDeleteModal = (id) => {
    deleteId.value = id
    deleteModal.value = true
  },
  hideDeleteModal = () => {
    deleteId.value = 0
    deleteModal.value = false
  },
  deleteProduct = () => {
    return apiClient.delete(`menu/product-delete?product=${deleteId.value}`).then(response => {
      if (response) {
        message.success('Продукт успешно удален')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      hideDeleteModal()
      reload()
    });
  },
  hideModal = () => {
    setTimeout(() => {
      modalVisible.value = false
    }, 100)
  },
  hideMultipleModal = () => {
    setTimeout(() => {
      multipleModalVisible.value = false
    }, 100)
  },
  closeEditSpicModal = (value) => {
    editSpicModalVisible.value = value
  },
  getData = (brand, category, params) => {
    loading.value = true
    return apiClient.get(`/menu/products?brand=${brand}&category=${category}`, {
      params,
    }).then(({data}) => {
      dataSource.value = data.data.items
      loading.value = false
      brands.value = data.data.brands
      productId.value = data.data.store
      brandId.value = data.data.brand
      selectedBrand.value = data.data.brand.toString()
      categories.value = data.data.categories
      editModalKey.value++
      units.value = data.data['units']
      multipleEditModalKey.value++
      locales.value = data.data.locales
      categoryName.value = data.data['category_name']
      total.value = data.data.total
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
      fetchCategories(selectedBrand.value)
    });
  },
  onEditInput = (type, id) => {
    switch (type) {
      case "name":
        editNameId.value = id;
        break;
      case "weight":
        editWeightId.value = id;
        break;
      case "unit":
        editUnitId.value = id;
        break;
      case "desc":
        editDescId.value = id;
        break;
      case "price":
        editPriceId.value = id;
        break;
      case "category":
        editCategoryId.value = id;
        break;
      case "spic":
        editSpicId.value = id;
        break;
      case "code":
        editCodeId.value = id;
        break;
    }
    nextTick(() => {
      const input = document.getElementById(`${type}-${id}`);
      if (input)
        input.focus();
    });
  },
  reload = () => {
    getData(route.params.brand, route.params.category, {
      ...queryParams,
    })
  };

onBeforeRouteUpdate((to, from, next) => {
  getData(to.params.brand, to.params.category, {
    ...queryParams,
  })
  next()
})
onMounted(() => {
  if (route.params.category === 'all') {
    selectedCategory.value = 'all'
  }
  if (route.params.category) {
    selectedCategory.value = route.params.category
  }
  getData(route.params.brand, route.params.category, {
    ...queryParams,
  })
})

watch(selectedRowKeys, (value) => {
  showSelectAllButton.value = value.length === queryParams['per-page']
})

watch(spicValue.value, () => {
  spicData.value = [];
  spicFetching.value = false;
});

watch(codeValue.value, () => {
  codeData.value = [];
  codeFetching.value = false;
});

watch(editSpicId, (value) => {
  spicData.value = []
  spicValue.value = []
  if (!value)
    return;
  const record = dataSource.value.find(item => item['id'] === value)
  if (!record['spic'])
    return;
  if (record['spicModel']?.['name'] && record['spicModel']?.['id']) {
    spic.value = {
      label: record['spicModel']?.['name'],
      value: record['spicModel']?.['id'],
    }
  } else {
    spic.value = {
      label: record.spic,
      value: record.spic,
    }
  }
})

watch(editCodeId, (value) => {
  codeData.value = []
  codeValue.value = []
  if (!value)
    return;
  const record = dataSource.value.find(item => item['id'] === value)
  // if (!record['spic'])
  //   return;
  fetchCode(record['id'])
})

watch(queryParams, () => {
  router.push({
    name: 'products',
    params: {
      category: route.params.category,
    },
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData(route.params.brand, route.params.category, {
    ...route.query,
    ...queryParams,
  })
});

const query = {...toRefs(queryParams)}

</script>
<style lang="scss">
@import "./index.css";

</style>
